export default function Booking() {
  return (
    <div style={{ position: "relative", marginTop: "50px" }}>
      <iframe
        src="https://outlook.office365.com/owa/calendar/hiqsense@hiqsense.ca/bookings/"
        width="100%"
        height="1800px"
      ></iframe>
    </div>
  );
}
