import React from 'react'

export const Whirl = () => {
    return (
        <svg width="3440" height="100%" viewBox="0 0 1440 450" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute left-0 opacity-50 z-20'>
            <g opacity="0.06">
                <path d="M1503.15 178.626C1414.99 138.962 1184.15 -27.8882 1079.96 -11.7629C975.776 4.36236 1105.04 236.984 1003.05 256.027C901.072 275.071 707.868 81.0255 590.449 79.644C473.031 78.2624 543.003 232.437 439.445 249.396C335.888 266.355 188.426 132.834 93.3751 161.047C-1.6762 189.26 78.2504 356.607 -16.8009 384.82C-111.852 413.033 -253.014 287.849 -362.871 296.471C-472.729 305.093 -438.986 411.333 -544.118 426.207C-649.25 441.082 -769.935 342.991 -867.506 367.87C-965.078 392.749 -900.084 540.338 -1012.46 545.625C-1124.84 550.913 -1324.74 424.996 -1406.92 393.251" stroke="url(#paint0_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M1466.64 219.417C1385.17 177.969 1178.18 7.38205 1075.6 20.4692C973.011 33.5564 1074.97 266.214 974.234 282.235C873.495 298.256 705.698 101.887 592.044 97.3707C478.391 92.8542 530.756 246.631 428.699 260.556C326.641 274.482 197.11 138.971 102.169 164.213C7.22677 189.455 67.9198 356.476 -27.022 381.718C-121.964 406.96 -246.224 279.832 -353.552 285.375C-460.88 290.918 -438.823 396.494 -542.198 408.323C-645.573 420.153 -752.703 320.269 -849.753 342.158C-946.803 364.047 -898.602 511.201 -1008.04 513.391C-1117.48 515.58 -1298.59 386.152 -1375.05 352.668" stroke="url(#paint1_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M1469.14 290.591C1394.41 247.654 1163.29 40.9542 1062.84 50.9776C962.391 61.001 1038.36 292.234 939.406 305.189C840.455 318.143 697.316 120.725 587.877 113.161C478.438 105.596 514.119 258.016 414.098 268.877C314.077 279.738 202.018 143.125 107.777 165.293C13.5353 187.461 55.9802 353.115 -38.2612 375.282C-132.503 397.45 -240.281 269.212 -344.583 271.698C-448.885 274.184 -437.82 378.446 -538.912 387.213C-640.003 395.98 -733.867 294.963 -829.821 313.78C-925.775 332.598 -893.476 478.402 -999.49 477.537C-1105.5 476.673 -1268.02 344.611 -1338.69 309.63" stroke="url(#paint2_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M1484.91 383.844C1416.94 340.121 1138.08 72.4684 1040.35 79.3867C942.607 86.3051 994.024 312.662 897.453 322.464C800.882 332.267 681.55 136.829 576.805 126.44C472.06 116.052 492.083 264.857 394.678 272.599C297.273 280.342 202.161 144.736 109.26 163.604C16.3591 182.473 41.6538 344.299 -51.2473 363.167C-144.148 382.036 -235.924 254.671 -336.665 254.172C-437.406 253.673 -436.567 355.09 -534.806 360.772C-633.045 366.455 -713.977 265.875 -808.213 281.446C-902.449 297.018 -885.061 439.313 -987.137 435.517C-1089.21 431.722 -1233.38 299.122 -1298.18 263.228" stroke="url(#paint3_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M1453.76 457.466C1345.84 381.187 1112.29 103.003 1017.43 106.968C922.577 110.933 951.179 334.213 857.183 341.038C763.186 347.863 666.255 152.922 566.249 139.727C466.244 126.531 471.765 272.917 377.155 277.699C282.546 282.481 203.421 146.866 112.123 162.68C20.8248 178.493 30.2215 337.791 -61.0769 353.604C-152.375 369.418 -229.046 241.974 -326.108 238.585C-423.171 235.195 -431.753 334.595 -526.976 337.334C-622.198 340.073 -690.898 239.187 -783.178 251.732C-875.457 264.277 -871.873 404.207 -969.917 397.549C-1067.96 390.891 -1194.65 256.809 -1253.79 219.772" stroke="url(#paint4_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M1453.76 542.617C1269.36 403.163 1074.85 131.484 983.492 132.879C892.134 134.273 902.214 346.957 811.456 351.088C720.699 355.218 642.826 167.732 547.859 152.707C452.891 137.681 446.8 276.789 355.613 278.965C264.426 281.142 199.029 150.42 110.162 163.152C21.2957 175.884 17.9195 327.346 -70.9472 340.077C-159.814 352.809 -223.492 229.906 -316.397 224.264C-409.302 218.621 -425.275 312.77 -516.891 312.992C-608.508 313.213 -666.601 215.722 -756.155 225.326C-845.709 234.93 -853.158 367.862 -946.751 359.091C-1040.34 350.321 -1151.51 219.867 -1205.4 183.229" stroke="url(#paint5_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M1365.44 479.312C1282.25 435.916 1071.32 205.472 981.277 204.816C891.239 204.161 881.086 358.259 791.382 359.595C701.678 360.93 642.736 223.831 550.696 211.227C458.656 198.623 439.533 299.182 349.59 299.095C259.648 299.008 207.627 203.216 118.971 210.81C30.3153 218.404 12.6982 327.953 -75.9576 335.547C-164.613 343.141 -215.681 253.039 -306.577 247.263C-397.473 241.486 -422.078 309.329 -512.259 307.82C-602.44 306.311 -650.409 234.7 -739.446 240.018C-828.483 245.336 -848.359 341.4 -939.637 333.348C-1030.91 325.296 -1128.01 228.863 -1177.58 201.366" stroke="url(#paint6_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M1303.34 459.866C1210.05 407.917 1071.16 241.839 982.199 239.565C893.238 237.292 860.899 365.395 771.98 364.786C683.06 364.177 644.598 248.903 555.386 236.644C466.173 224.384 432.709 307.739 343.759 305.942C254.81 304.144 217.217 223.393 128.429 228.015C39.641 232.637 6.36536 323.506 -82.4225 328.128C-171.21 332.75 -208.684 256.754 -297.753 250.201C-386.822 243.649 -420.975 299.66 -509.954 296.674C-598.934 293.688 -636.018 233.147 -724.854 235.867C-813.69 238.587 -847.249 318.185 -936.366 309.73C-1025.48 301.275 -1107.56 219.126 -1152.61 195.283" stroke="url(#paint7_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M1233.08 472.751C1149.67 427.996 1039.05 260.657 953.604 256.745C868.161 252.832 819.215 377.85 733.583 375.571C647.95 373.291 626.866 259.515 542.567 245.804C458.268 232.094 414.444 313.207 328.948 309.761C243.451 306.315 218.414 226.412 132.183 229.265C45.9513 232.118 1.26793 320.603 -84.9636 323.456C-171.195 326.309 -196.776 251.072 -281.728 242.96C-366.681 234.849 -407.374 289.133 -492.734 284.521C-578.095 279.909 -605.445 219.835 -691.459 220.822C-777.473 221.809 -820.866 299.235 -905.601 289.258C-990.335 279.28 -1058.06 197.164 -1098.18 172.929" stroke="url(#paint8_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M1161.36 478.389C1077.37 440.298 999.753 279.073 918.441 273.623C837.129 268.174 773.342 388.514 691.627 384.645C609.911 380.777 605.1 269.987 526.208 255.055C447.316 240.122 394.372 317.966 312.945 312.968C231.518 307.971 218.34 229.967 135.357 231.066C52.3746 232.166 -2.38965 317.145 -85.3724 318.244C-168.355 319.343 -182.685 245.856 -262.96 236.342C-343.235 226.829 -389.553 278.706 -470.692 272.579C-551.831 266.453 -569.906 207.642 -652.428 206.935C-734.95 206.228 -786.983 280.504 -866.797 269.184C-946.611 257.864 -1000.38 176.888 -1035.53 152.599" stroke="url(#paint9_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M1064.58 466.51C1004.97 433.411 958.662 295.82 881.709 288.99C804.756 282.16 727.925 396.672 650.378 391.355C572.831 386.037 582.873 279.372 509.484 263.467C436.096 247.562 375.237 321.408 298.115 315.01C220.992 308.612 218.71 233.321 139.297 232.758C59.8827 232.194 -3.65796 312.868 -83.0719 312.305C-162.486 311.741 -166.465 240.771 -241.89 230.052C-317.315 219.333 -368.414 268.33 -445.112 260.852C-521.81 253.374 -531.306 196.449 -610.041 194.157C-688.776 191.865 -748.294 262.297 -823.04 249.849C-897.786 237.401 -938.451 158.458 -968.822 134.407" stroke="url(#paint10_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M957.193 434.332C933.454 406.871 915.647 310.555 843.243 302.517C770.84 294.478 682.821 402.353 609.655 395.747C536.49 389.142 559.881 287.448 492.048 270.811C424.216 254.173 356.681 323.516 284.06 315.887C211.438 308.258 219.025 236.292 143.465 234.191C67.9052 232.09 -3.06887 307.902 -78.629 305.801C-154.189 303.7 -148.779 235.829 -219.223 224.105C-289.668 212.381 -344.686 258.179 -416.763 249.527C-488.84 240.874 -490.505 186.311 -565.194 182.571C-639.883 178.832 -705.699 244.941 -775.272 231.579C-844.846 218.217 -873.341 142.007 -899.149 118.435" stroke="url(#paint11_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M893.026 446.835C873.89 419.907 869.194 326.787 801.175 317.581C733.156 308.375 635.464 410.486 566.536 402.645C497.608 394.803 532.883 297.337 470.321 279.942C407.758 262.547 334.513 327.964 266.235 319.148C197.956 310.331 214.37 241.176 142.584 237.624C70.7983 234.072 -6.55125 305.65 -78.337 302.098C-150.123 298.546 -136.307 233.29 -201.988 220.574C-267.668 207.858 -325.974 250.853 -393.603 241.062C-461.232 231.271 -455.86 178.688 -526.607 173.576C-597.353 168.465 -668.545 230.801 -733.186 216.525C-797.828 202.25 -815.281 128.277 -836.884 105.053" stroke="url(#paint12_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M828.862 456.969C814.015 430.77 821.162 341.433 757.598 331.215C694.033 320.997 588.352 416.852 523.752 407.924C459.152 398.995 504.87 306.313 447.518 288.359C390.165 270.405 312.321 331.595 248.46 321.746C184.6 311.897 208.842 245.959 140.988 241.082C73.1341 236.206 -9.38469 303.217 -77.2386 298.341C-145.093 293.464 -123.809 231.21 -184.711 217.677C-245.613 204.144 -306.448 244.152 -369.569 233.382C-432.69 222.611 -421.02 172.329 -487.691 165.98C-554.361 159.63 -629.869 217.91 -689.588 202.903C-749.307 187.897 -756.685 116.647 -774.342 93.9479" stroke="url(#paint13_linear_536_1827)" strokeWidth="6.6946" strokeLinecap="round" />
                <path d="M765.084 464.75C754.198 439.447 771.899 354.366 712.828 343.298C653.757 332.23 541.755 421.483 481.544 411.624C421.333 401.765 476.045 314.293 423.815 295.974C371.584 277.654 290.235 334.414 230.838 323.692C171.441 312.969 202.505 250.566 138.711 244.505C74.9164 238.444 -11.5799 300.66 -75.3741 294.599C-139.168 288.538 -111.362 229.588 -167.501 215.412C-223.641 201.237 -286.26 238.142 -344.842 226.556C-403.425 214.97 -386.205 167.243 -448.697 159.8C-511.188 152.358 -589.964 206.39 -644.8 190.833C-699.637 175.277 -697.929 107.15 -711.911 85.1278" stroke="url(#paint14_linear_536_1827)" strokeWidth="6.6946" strokeLinecap="round" />
                <path d="M702.07 470.207C694.802 445.944 721.753 365.498 667.186 353.743C612.619 341.989 495.939 424.418 440.149 413.788C384.358 403.158 446.616 321.218 399.391 302.719C352.167 284.221 268.386 336.428 213.47 324.995C158.553 313.562 195.429 254.938 135.792 247.841C76.1557 240.744 -13.1484 298.026 -72.7849 290.928C-132.421 283.831 -99.0424 228.418 -150.463 213.774C-201.883 199.129 -265.56 232.87 -319.602 220.635C-373.644 208.399 -351.628 163.424 -409.866 155.042C-468.104 146.66 -549.122 196.33 -599.144 180.401C-649.166 164.472 -639.382 99.7943 -649.971 78.5819" stroke="url(#paint15_linear_536_1827)" strokeWidth="6.6946" strokeLinecap="round" />
                <path d="M640.183 473.384C636.185 450.282 671.074 374.767 620.993 362.492C570.912 350.216 451.161 425.701 399.793 414.463C348.426 403.225 416.791 327.047 374.429 308.549C332.067 290.051 246.903 337.651 196.454 325.672C146.006 313.693 187.686 259.028 132.275 251.05C76.8648 243.072 -14.1062 295.354 -69.5169 287.375C-124.928 279.397 -86.9226 227.696 -133.696 212.754C-180.469 197.811 -244.498 228.372 -294.027 215.653C-343.557 202.933 -317.498 160.863 -371.438 151.699C-425.379 142.535 -507.639 187.794 -552.941 171.667C-598.244 155.539 -581.402 94.5742 -588.892 74.2867" stroke="url(#paint16_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M579.776 474.334C578.691 452.494 620.21 382.136 574.568 369.505C528.926 356.873 407.667 425.384 360.696 413.702C313.726 402.019 386.778 331.754 349.11 313.428C311.441 295.103 225.908 338.098 179.887 325.738C133.865 313.377 179.354 262.798 128.207 254.098C77.0607 245.398 -14.471 292.678 -65.6178 283.978C-116.765 275.279 -75.0724 227.411 -117.297 212.339C-159.522 197.267 -223.224 224.671 -268.296 211.633C-313.368 198.595 -284.015 159.539 -333.643 149.755C-383.271 139.97 -465.805 180.824 -506.511 164.667C-547.217 148.511 -524.34 91.4665 -529.032 72.2031" stroke="url(#paint17_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M521.192 473.12C522.658 452.629 569.505 387.587 528.229 374.763C486.952 361.938 365.695 423.525 323.067 411.561C280.44 399.597 356.785 335.324 323.617 317.335C290.449 299.346 205.522 337.792 163.86 325.214C122.198 312.635 170.513 266.216 123.638 256.957C76.764 247.698 -14.2628 290.029 -61.1372 280.77C-108.012 271.511 -63.5577 227.552 -101.359 212.514C-139.16 197.476 -201.886 221.782 -242.583 208.588C-283.28 195.394 -251.374 159.427 -296.704 149.184C-342.034 138.941 -423.911 175.445 -460.168 159.423C-496.424 143.402 -468.535 90.4349 -470.737 72.2801" stroke="url(#paint18_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M464.755 469.818C468.407 450.743 519.295 391.119 482.284 378.261C445.273 365.404 325.467 420.186 287.101 408.102C248.735 396.018 327.012 337.753 298.128 320.257C269.245 302.761 185.858 336.758 148.46 324.121C111.062 311.485 161.24 269.256 118.617 259.601C75.994 249.947 -13.5076 287.434 -56.1307 277.779C-98.7538 268.125 -52.4459 228.104 -85.9738 213.259C-119.502 198.414 -180.634 219.71 -217.065 206.522C-253.495 193.333 -219.766 160.491 -260.841 149.953C-301.916 139.415 -382.245 171.667 -414.224 155.938C-446.204 140.209 -414.319 91.4303 -414.343 74.4545" stroke="url(#paint19_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M410.776 464.513C416.249 446.908 469.917 392.744 437.045 380.01C404.172 367.277 287.2 415.439 252.987 403.393C218.775 391.347 297.663 339.044 272.827 322.188C247.99 305.333 167.029 335.024 133.774 322.487C100.518 309.95 151.623 271.898 113.201 262.011C74.7792 252.124 -12.2287 284.916 -50.6504 275.029C-89.072 265.142 -41.7946 229.053 -71.2231 214.554C-100.652 200.054 -159.608 218.457 -191.907 205.429C-224.206 192.402 -189.366 162.693 -226.257 152.02C-263.148 141.348 -341.086 169.488 -368.983 154.203C-396.881 138.918 -362.003 94.3916 -360.166 78.6518" stroke="url(#paint20_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M359.544 457.3C366.473 441.203 421.688 392.489 392.803 380.032C363.917 367.575 251.086 409.36 220.894 397.507C190.702 385.653 268.927 339.211 247.881 323.134C226.835 307.057 149.131 332.621 119.872 320.337C90.6135 308.052 141.737 274.125 107.439 264.168C73.1401 254.211 -10.4617 282.498 -44.7601 272.541C-79.0585 262.584 -31.6681 230.381 -57.1939 216.373C-82.7197 202.364 -138.958 218.016 -167.284 205.3C-195.609 192.585 -160.351 165.985 -193.157 155.338C-225.962 144.691 -300.716 168.893 -324.749 154.195C-348.781 139.497 -311.895 99.2474 -308.513 84.7874" stroke="url(#paint21_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M311.335 448.284C319.359 433.716 374.926 390.393 349.852 378.359C324.779 366.325 217.313 402.031 190.983 390.52C164.652 379.009 240.997 338.275 223.466 323.104C205.935 307.934 132.265 329.586 106.832 317.701C81.3995 305.816 131.671 275.926 101.39 266.058C71.1087 256.189 -8.23587 280.201 -38.5169 270.333C-68.798 260.464 -22.1181 232.067 -43.9592 218.689C-65.8004 205.311 -118.82 218.375 -143.354 206.117C-167.889 193.859 -132.881 170.316 -161.726 159.85C-190.57 149.384 -261.403 169.856 -281.808 155.88C-302.212 141.905 -264.28 105.915 -259.668 92.7663" stroke="url(#paint22_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M266.403 437.576C275.169 424.547 329.935 386.507 308.476 375.036C287.017 363.565 186.052 393.54 163.401 382.515C140.75 371.49 214.061 336.262 199.752 322.116C185.444 307.97 116.522 325.956 94.7222 314.613C72.9228 303.269 121.512 277.291 95.1153 267.667C68.719 258.043 -5.58402 278.043 -31.9804 268.419C-58.3767 258.795 -13.1929 234.091 -31.5872 221.473C-49.9814 208.856 -99.3247 219.517 -120.273 207.855C-141.221 196.193 -107.104 175.629 -132.138 165.495C-157.172 155.362 -223.405 172.342 -240.437 159.215C-257.47 146.088 -219.423 114.304 -213.893 102.485" stroke="url(#paint23_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M224.976 425.298C234.135 413.802 287.001 380.893 268.938 370.119C250.875 359.344 157.448 383.98 138.273 373.579C119.098 363.178 188.288 333.208 176.897 320.193C165.507 307.177 101.979 321.774 83.5978 311.106C65.2169 300.438 111.34 278.214 88.6694 268.986C65.9991 259.759 -2.54937 276.041 -25.2197 266.813C-47.89 257.586 -4.94452 236.429 -20.148 224.694C-35.3515 212.959 -80.6098 221.42 -98.1963 210.485C-115.783 199.551 -83.1639 181.861 -104.563 172.207C-125.963 162.552 -186.981 176.306 -200.913 164.144C-214.846 151.983 -177.58 124.313 -171.439 113.831" stroke="url(#paint24_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M187.261 411.576C196.478 401.596 246.406 373.622 231.501 363.669C216.595 353.716 131.638 373.449 115.713 363.802C99.7884 354.154 163.852 329.151 155.061 317.363C146.27 305.574 88.7124 317.082 73.5154 307.216C58.3185 297.35 101.244 278.693 82.1158 270.007C62.9881 261.322 0.830061 274.21 -18.2977 265.525C-37.4254 256.839 2.58793 239.056 -9.69733 228.316C-21.9826 217.576 -62.798 224.058 -77.267 213.974C-91.736 203.889 -61.1856 188.947 -79.1487 179.912C-97.1117 170.876 -152.369 181.694 -163.49 170.604C-174.61 159.515 -138.978 135.833 -132.527 126.683" stroke="url(#paint25_linear_536_1827)" strokeWidth="6.6946" strokeLinecap="round" />
                <path d="M153.439 396.548C162.39 388.051 208.411 364.775 196.405 355.761C184.4 346.746 108.734 362.048 95.8144 353.277C82.8951 344.507 140.913 324.139 134.393 313.661C127.873 303.183 76.7858 311.927 64.5196 302.982C52.2533 294.037 91.3075 278.729 75.515 270.725C59.7225 262.721 4.50802 272.566 -11.2845 264.562C-27.077 256.558 9.36514 241.947 -0.289072 232.305C-9.94328 222.663 -46.0115 227.4 -57.6247 218.281C-69.238 209.162 -41.2849 196.816 -56.0326 188.533C-70.7803 180.25 -119.804 188.444 -128.414 178.523C-137.023 168.602 -103.828 148.749 -97.3577 140.914" stroke="url(#paint26_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M123.663 380.352C132.043 373.294 173.264 354.441 163.887 346.472C154.509 338.504 88.8286 349.884 78.6523 342.103C68.4761 334.322 119.624 318.222 115.041 309.126C110.457 300.03 66.2564 306.357 56.6508 298.442C47.0452 290.527 81.621 278.324 68.9337 271.135C56.2464 263.945 8.43921 271.12 -4.24805 263.93C-16.9353 256.74 15.3577 245.075 8.03484 236.623C0.711944 228.171 -30.363 231.411 -39.3979 223.362C-48.4328 215.313 -23.5586 205.392 -35.3328 197.987C-47.107 190.583 -89.5042 196.486 -95.914 187.819C-102.324 179.153 -72.3111 162.936 -66.0998 156.388" stroke="url(#paint27_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M98.0577 363.136C105.579 357.46 141.198 342.718 134.161 335.894C127.124 329.07 71.9912 337.065 64.2791 330.379C56.567 323.694 100.13 311.456 97.1426 303.802C94.1551 296.147 57.1689 300.423 49.9389 293.638C42.7088 286.854 72.2718 277.486 62.4384 271.235C52.6051 264.984 12.5721 269.885 2.73869 263.634C-7.09469 257.383 20.5399 248.411 15.2383 241.231C9.93662 234.051 -15.9612 236.053 -22.7091 229.17C-29.4571 222.286 -8.08982 214.599 -17.1518 208.19C-26.2139 201.781 -61.6766 205.744 -66.2069 198.406C-70.7371 191.068 -44.5866 178.267 -38.8971 172.967" stroke="url(#paint28_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M76.7068 345.05C83.1071 340.689 112.426 329.711 107.428 324.119C102.431 318.527 58.2616 323.705 52.7204 318.208C47.1792 312.712 82.5637 303.903 80.8308 297.737C79.0978 291.571 49.555 294.177 44.4024 288.612C39.2498 283.048 63.3493 276.222 56.0982 271.027C48.8472 265.831 16.8484 268.869 9.59737 263.673C2.34633 258.478 24.8914 251.925 21.2932 246.088C17.695 240.25 -2.91004 241.284 -7.67404 235.651C-12.438 230.018 5.05531 224.354 -1.57397 219.049C-8.20325 213.745 -36.5182 216.135 -39.4946 210.187C-42.4711 204.24 -20.7846 194.604 -15.861 190.503" stroke="url(#paint29_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M59.6574 326.25C64.703 323.124 87.1435 315.531 83.876 311.249C80.6085 306.967 47.6493 309.917 43.9734 305.695C40.2976 301.473 67.0495 295.626 66.2321 290.983C65.4146 286.34 43.4339 287.672 40.0498 283.407C36.6656 279.142 54.9473 274.543 49.9881 270.51C45.029 266.477 21.2049 268.082 16.2457 264.049C11.2866 260.016 28.4016 255.589 26.1842 251.152C23.9667 246.715 8.69443 247.06 5.60196 242.751C2.50948 238.443 15.8328 234.575 11.3403 230.473C6.84779 226.372 -14.2113 227.569 -15.9621 223.063C-17.7128 218.557 -1.00074 211.808 2.93645 208.846" stroke="url(#paint30_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M46.9029 306.893C50.3963 304.914 65.527 300.297 63.6712 297.39C61.8155 294.484 40.1214 295.818 37.9953 292.944C35.8692 290.07 53.7001 286.695 53.4661 283.596C53.2322 280.496 38.8052 280.964 36.8722 278.067C34.9392 275.17 47.1634 272.463 44.1878 269.69C41.2123 266.918 25.565 267.531 22.5894 264.758C19.6139 261.985 31.0658 259.37 29.9051 256.381C28.7444 253.392 18.758 253.331 17.0181 250.411C15.2782 247.491 24.2201 245.175 21.5535 242.366C18.8868 239.556 5.06995 239.951 4.21815 236.926C3.36635 233.9 14.7051 229.734 17.4648 227.842" stroke="url(#paint31_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
                <path d="M38.3797 287.143C40.1703 286.208 47.7411 284.13 46.9746 282.655C46.2081 281.181 35.6 281.526 34.7006 280.063C33.8012 278.601 42.6267 277.181 42.6576 275.634C42.6886 274.087 35.6535 274.108 34.849 272.636C34.0446 271.165 40.1133 269.996 38.7962 268.571C37.4792 267.146 29.8442 267.222 28.5272 265.797C27.2101 264.372 32.8991 263.238 32.4744 261.732C32.0496 260.226 27.1979 260.049 26.4884 258.569C25.7789 257.089 30.2339 256.066 29.0687 254.628C27.9035 253.189 21.1684 253.183 20.8956 251.663C20.6227 250.144 26.3291 248.235 27.759 247.333" stroke="url(#paint32_linear_536_1827)" strokeWidth="6.69459" strokeLinecap="round" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_536_1827" x1="-55.4706" y1="148.878" x2="-19.148" y2="405.6" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint1_linear_536_1827" x1="-40.8606" y1="148.224" x2="-12.0581" y2="399.917" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint2_linear_536_1827" x1="-28.9477" y1="145.656" x2="-7.58334" y2="392.003" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint3_linear_536_1827" x1="-20.6396" y1="140.747" x2="-6.84248" y2="379.288" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint4_linear_536_1827" x1="-10.9759" y1="136.543" x2="-4.25405" y2="370.233" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint5_linear_536_1827" x1="-5.96516" y1="135.264" x2="-5.42873" y2="357.253" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint6_linear_536_1827" x1="7.32204" y1="188.512" x2="4.62827" y2="349.468" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint7_linear_536_1827" x1="21.2233" y1="207.309" x2="16.7033" y2="341.983" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#22DAE5" />
                </linearGradient>
                <linearGradient id="paint8_linear_536_1827" x1="31.7102" y1="206.93" x2="24.5325" y2="339.776" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint9_linear_536_1827" x1="42.1397" y1="207.454" x2="32.3332" y2="337.331" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint10_linear_536_1827" x1="53.3707" y1="208.223" x2="41.073" y2="334.351" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint11_linear_536_1827" x1="64.8109" y1="209.063" x2="50.1825" y2="330.917" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint12_linear_536_1827" x1="70.7844" y1="211.833" x2="53.5792" y2="330.671" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint13_linear_536_1827" x1="75.8949" y1="214.894" x2="56.1881" y2="330.388" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint14_linear_536_1827" x1="80.1411" y1="218.169" x2="58.0128" y2="330.057" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint15_linear_536_1827" x1="83.5304" y1="221.594" x2="59.065" y2="329.651" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint16_linear_536_1827" x1="86.0748" y1="225.116" x2="59.3628" y2="329.131" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint17_linear_536_1827" x1="87.7918" y1="228.689" x2="58.9331" y2="328.45" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint18_linear_536_1827" x1="88.7045" y1="232.27" x2="57.8135" y2="327.556" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint19_linear_536_1827" x1="88.8367" y1="235.821" x2="56.0487" y2="326.39" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint20_linear_536_1827" x1="88.2223" y1="239.309" x2="53.7031" y2="324.89" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint21_linear_536_1827" x1="86.8897" y1="242.702" x2="50.8466" y2="322.993" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint22_linear_536_1827" x1="84.8802" y1="245.97" x2="47.5768" y2="320.633" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint23_linear_536_1827" x1="82.2388" y1="249.085" x2="44.0128" y2="317.749" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint24_linear_536_1827" x1="79.0066" y1="252.023" x2="40.292" y2="314.288" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint25_linear_536_1827" x1="75.2373" y1="254.759" x2="36.5894" y2="310.212" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint26_linear_536_1827" x1="70.9845" y1="257.272" x2="33.1068" y2="305.509" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint27_linear_536_1827" x1="66.3113" y1="259.542" x2="30.0816" y2="300.21" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint28_linear_536_1827" x1="61.2847" y1="261.552" x2="27.7718" y2="294.404" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint29_linear_536_1827" x1="55.9784" y1="263.283" x2="26.4395" y2="288.265" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint30_linear_536_1827" x1="50.4782" y1="264.722" x2="26.3192" y2="282.064" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint31_linear_536_1827" x1="44.8807" y1="265.855" x2="27.5603" y2="276.176" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
                <linearGradient id="paint32_linear_536_1827" x1="39.3121" y1="266.671" x2="30.1693" y2="271.054" gradientUnits="userSpaceOnUse">
                    <stop offset="0.45" stopColor="#00AFEF" />
                    <stop offset="1" stopColor="#FEC158" />
                </linearGradient>
            </defs>
        </svg>
    )
}